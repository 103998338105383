<template>
    <div>
  
      <!-- Início principal -->
      <v-card>
        <v-toolbar flat>
          <v-icon>
            fas fa-key
          </v-icon>
          <v-toolbar-title class="pl-4">
            Tokens
          </v-toolbar-title>
          <v-spacer></v-spacer>
  
        <!-- Inserir Cálculo de Token -->
  
        <div class="text-center">
          <v-dialog
            v-model="dialog"
            max-width="700px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Adicionar
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Inserir Cálculo (Token)</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="4"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        label="Nome"
                        placeholder="Insira o nome"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        label="Token"
                        placeholder="Insira o token"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        label="Canal vendas"
                        placeholder="Insira o Canal de Vendas"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        label="Medidas"
                        placeholder="Insira as medidas"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                  >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <!-- Final do Dialogo -->
  
          <v-app-bar-nav-icon @click="modal.filtro = true">
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon @click="fechar">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
  
        </v-toolbar>
        <v-divider></v-divider>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
      </template>
        <v-card-text>
          <v-row>
            <v-col class="px-6 py-6">
              <v-data-table
                dense
                :headers="grid.tokens.cabecalho"
                :items="grid.tokens.items"
                :items-per-page="grid.tokens.paginacao"
                hide-default-footer
                class="elevation-1 pt-4"
                :loading="grid.tokens.carregando"
              >
                <template #item.token="{ item }">
                  <a @click="carregarDetalhes(item)" style="text-decoration: none" >
                    {{ item.token }}
                  </a>
                </template>
  
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="2">
            </v-col>
            <v-col cols="8">
              <v-pagination
                v-model="grid.tokens.paginaAtual"
                :length=" Math.ceil( grid.tokens.totalRegistros / this.grid.tokens.porPagina ) "
                @input="mudarPagina"
                :total-visible="5"
              ></v-pagination>
            </v-col>
            <v-col cols="2">
              <v-select
                dense
                outlined
                label="Resultados por página"
                :items="grid.tokens.qtdsPaginacao"
                item-text="text"
                item-value="value"
                v-model="grid.tokens.porPagina"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
  
      </v-card>
      <!-- Fim principal -->
  
    </div>
  </template>
  
  <script>
  import { baseApiUrl } from "@/global";
  import axios from "axios";
  
  export default {
    name: "Tokens",
    data: () => ({
      dialog: false,
      dialogDelete: false,
      codigo_empresa: '',
  
      grid: {
        tokens: {
          cabecalho: [
            { text: "Nome", value: "nome", sortable: false },
            { text: "Token", value: "token", sortable: false },
            { text: "Canal Vendas", value: "canal_vendas", sortable: false },
            { text: "Medidas", value: "tabel_prod", sortable: false },
            { text: "Ativo", value: "ativo", sortable: false },
            // { text: "Editar", value: "editar", sortable: false},
          ],
          items: [],
          carregando: false,
          paginaAtual: 1,
          totalRegistros: 0,
          porPagina: 100,
  
        },
      },
  // adicionar aqui as variáveis necessárias
  
  
  
    }),
    created() {
      this.codigo_empresa = Number(this.$store.state.cod_empresa);
    },
    mounted() {
      this.carregarTokens();
    },
    methods: {
  
      fechar() {
        this.$router.push("/");
      },
      carregarTokens() {
  
        let url = `${baseApiUrl}/empresas-token?cod_empresa=${this.codigo_empresa}`
        axios.get(url)
        .then(res => {
            this.grid.tokens.items = res.data.dados
        })
        .finally(() => this.grid.detalhes.carregando = false)
  
      },
    },
  };
  
  </script>
  
  <style>
  </style>